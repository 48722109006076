/**
 * Switch form enabled state
 * @param {Bool} enabledState - true to switch on enabled state, false to switch if off
 */
export function switchFormEnabledState(enabledState = true) {
	const allFormElementsToDisable = window.modForm.opts.form.querySelectorAll('.btn, input, [data-btn="back"], #swiperForm');

	allFormElementsToDisable.forEach((element) => {
		if (enabledState) {
			element.removeAttribute('disabled');
			element.style.opacity = '1';
			element.style.pointerEvents = 'all';
		} else {
			element.setAttribute('disabled', 'disabled');
			element.style.opacity = '.5';
			element.style.pointerEvents = 'none';
		}
	});
}