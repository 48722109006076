/**
 * Wire all form elements based on selectors
 */
export function initFormElements() {
	window.modForm.opts.formElemsSelectors = {...window.modForm.opts.formElemsSelectorsDefaults, ...window.modForm.opts.formElemsSelectors};
	Object.keys(window.modForm.opts.formElemsSelectors).forEach((key) => {
		if (!window.modForm.opts[key]) {
			window.modForm.opts[key] = window.modForm.opts.form.querySelectorAll(window.modForm.opts.formElemsSelectors[key]);
		}
	});
	// Storing original tcpa after getting the tcpaelement from its selector
	window.modForm.opts.originalTcpa = window.modForm.opts.tcpaCopy ? window.modForm.opts.tcpaCopy[0].innerHTML : '';
}
