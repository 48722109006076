import {
	switchFormLoadingState,
	populateFormWithZipData,
	populateLocationFieldsDynamically,
	getConsentListing
} from '../../../helpers';
import { isZipCodeValid } from '../../../validators';
/**
 * Track second zip change and validate on the go
 * @returns {boolean} - false if no zip1Field is found
 */
export function initTrackZip1Change() {
	if (!window.modForm.opts.zip1Field.length) {
		return false;
	}

	let previousZip;
	let isValid = true;
	const isValidating = window.modForm.opts.form.classList.contains(window.modForm.opts.formLoadingCls);

	window.modForm.opts.zip1Field.forEach(function(zip1Field) {
		// copy previous Zip
		zip1Field.addEventListener('focus', function() {
			previousZip = this.value;
		});

		zip1Field.addEventListener('keydown', function(e) {
			if (e.key === 'Enter' && (isValidating || !isValid)) {
				e.preventDefault();
			}
		});

		zip1Field.addEventListener('keyup', function() {
			const $input = this;
			const shouldValidateZip = this.value.length === 5 && ((this.value !== previousZip && !isValidating) || !isValid);

			if (shouldValidateZip) {
				switchFormLoadingState(true);

				isZipCodeValid($input.value, function(response) {
					if (response.success) {
						if (response.data) {
							populateFormWithZipData(response.data);
							populateLocationFieldsDynamically(response.data);
						}
						// update the previous zip value if edited zip is valid
						previousZip = $input.value;
						isValid = true;
						$input.closest('.form-group').classList.remove(window.modForm.opts.fieldErrorCls);
						window.modForm.opts.zipCode = response.data.zip;

						// If we're currently fetching live consent listing in the page, fetch it one more time with an updated zipcode
						if (window.modForm.opts.gettingLiveConsent) {
							getConsentListing(window.modForm.opts.getConsentListingCallback);
						}

						// Fire callback
						if ('function' === typeof window.modForm.opts.zip1FieldValidated) {
							window.modForm.opts.zip1FieldValidated(response.data);
						}
					} else {
						$input.closest('.form-group').classList.add(window.modForm.opts.fieldErrorCls);
						isValid = false;
					}
					switchFormLoadingState(false);
				});
			}
		});
	});
}
