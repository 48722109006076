import { formSubmitCallback } from '../../../helpers';
/**
 * Handles form (step) submission & validations
 * @param {boolean} [addEvent=true] - Determines whether to add event listener
 */
export function initFormSubmit(addEvent = true) {
	if (window.modForm.opts.isFormSubmitting) {
		return;
	}

	const form = window.modForm.opts.form;

	if (addEvent) {
		form.addEventListener('submit', formSubmitCallback);
	} else {
		form.removeEventListener('submit', formSubmitCallback);
	}
}
