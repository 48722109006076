/* globals modForm modUtils Modalytics */
/**
 * HS Endpoints:
 * Dev: https://hsapiservice.dev.quinstreet.net/calls/matches
 * Stage: https://hsapiservice.quinstage.com/calls/matches
 * Prod: https://form-service-hs.qnst.com/calls/matches
 *
 * Quest Endpoints:
 * Dev: ivr.dev.quinstreet.net/number
 * Stage: ivr.quinstage.com/number
 * Prod: ivr.quinstreet.com/number
 */
/**
 * This function gets the phone assignment data from the quest api.
 * @param {object} trafficDetails - Accepts response object of `TrafficDetails` api.
 * @param {boolean} shouldUpdateExistingPhoneAssignment - Pass `true` if this function is being called multiple times
 * @returns {void}
 */
let abortController = null;
export const getPhoneAssignmentData = function(trafficDetails, shouldUpdateExistingPhoneAssignment = false) {
	if (abortController) {
		abortController.abort('Current pending request has been aborted. Initiating a new request.');
	}
	const currentParams = modUtils.getUrlParamsToObject();
	const campaignAttributes = ['ad', 'gclid', 'msclkid', 'vmcid', 'tbcid', 'fb_ad_id', 'fb_campaign_id', 'fbclid', 'fb_adset_id'];
	const { CCID, CLK, QTR, tagId } = trafficDetails;
	const { phoneNumberContainerSelector, phoneNumberLinksSelector, useHsEndpoint, phoneNumbersTextSelector, matchVendors, formatNumberWithBracketsAndDash, skipMatching } = modForm.opts.phoneAssignmentConfig;
	const { vendorKey, PartnerSourceID } = currentParams;
	const TradingPlatformTrackingInfo = {};
	const isProductionEnv = modUtils.getEnv() === 'production';
	const isLocalEnv = modUtils.getEnv() === 'local';
	const phoneNumberRegex = new RegExp(/(\d{3})(\d{3})(\d{4})/g);
	const phoneNumberContainers = document.querySelectorAll(phoneNumberContainerSelector);
	const phoneNumberLinks = document.querySelectorAll(phoneNumberLinksSelector);
	let url = '';
	let payload = {};
	let requestBody = payload;

	const hsApiDomain = `https://${isProductionEnv ? 'form-service-hs.qnst.com' : 'hsapiservice.quinstage.com'}`;
	const questApiDomain = `https://${isProductionEnv ? 'ivr.quinstreet.com' : 'ivr.quinstage.com'}`;
	const requestHeaders = new Headers();
	requestHeaders.append('Accept', 'application/json');

	const hidePhoneNumberContainers = () => {
		document.body.classList.remove('call-tracking');
		Modalytics.heap('track', ['Phone Assignment', {
			phoneNumber: false,
			updatedExistingPhoneAssignment: shouldUpdateExistingPhoneAssignment
		}]);
	};

	const showPhoneNumber = (phoneNumber, extension, phoneAssignmentKey) => {
		const shouldDisplayPhoneNumber = (phoneNumberLinks && phoneNumberLinks.length) && (phoneNumberContainers && phoneNumberContainers.length) && phoneNumber;

		if (shouldDisplayPhoneNumber) {
			document.body.classList.add('call-tracking');
			const formattedPhoneNumber = phoneNumber.replace(phoneNumberRegex, `${formatNumberWithBracketsAndDash ? '($1) $2-$3' : '$1-$2-$3'}`);
			const formattedPhoneNumberWithExtension = `${formattedPhoneNumber}${extension ? ` <span class="call-extension">Ext: ${extension}</span>` : ''}`;
			modForm.opts.phoneAssignmentConfig.response = {
				displayNumber: formattedPhoneNumberWithExtension,
				hrefPhoneNumber: `${phoneNumber}${extension ? `,${extension}` : ''}`,
				extension,
				phoneNumber,
				formattedPhoneNumber,
				phoneAssignmentKey
			};
			phoneNumberLinks.forEach((phoneNumberLink) => {
				phoneNumberLink.setAttribute('href', `tel:${phoneNumber}${extension ? `,${extension}` : ''}`);
				const phoneNumberTextNode = phoneNumberLink.querySelector(phoneNumbersTextSelector);
				if (phoneNumberTextNode) {
					phoneNumberTextNode.innerHTML = formattedPhoneNumberWithExtension;
				}
			});

			Modalytics.heap('track', ['Phone Assignment', {
				phoneNumber: true,
				extension: extension ? true : false,
				updatedExistingPhoneAssignment: shouldUpdateExistingPhoneAssignment
			}]);
		}
	};

	const zip = modForm.opts?.zipCode || null;
	let zipForLocalizedAssignment = null;
	if (!zip) {
		zipForLocalizedAssignment = window.geolocation?.currentLocation?.zipcode || null;
	}

	if (useHsEndpoint) {
		requestHeaders.append('Content-Type', 'application/json');
		url = `${hsApiDomain}/calls/matches`;

		payload = {
			ccid: CCID || null,
			clk: CLK || null,
			qtr: QTR || null,
			zipForLocalizedAssignment,
			zip,
			service: modForm.opts.vertical,
			landingPageURL: window.location.href,
			matchVendors: (Array.isArray(matchVendors) && matchVendors.length) ? matchVendors : [-1],
			skipMatching: skipMatching,
			site: modForm?.opts?.websiteName || null
		};

		if (!payload.zip && isLocalEnv) {
			payload.zip = '94404';
		}

		// If VWO is loaded and there's an active campaign
		if (window._vwo_campaignData && Object.keys(window._vwo_campaignData).length !== 0) {
			payload.trackingInfo = {
				'VWOID': Object.keys(window._vwo_campaignData)[0],
				'VWOVariationName': Object.values(window._vwo_campaignData)[0].n
			};
		}

		if (shouldUpdateExistingPhoneAssignment && modForm.opts.phoneAssignmentConfig.response && modForm.opts.phoneAssignmentConfig.response.phoneAssignmentKey) {
			payload.vendorPhoneAssignmentKeys = {
				[payload.matchVendors[0]]: modForm.opts.phoneAssignmentConfig.response.phoneAssignmentKey
			};
		}

		requestBody = JSON.stringify(payload);
	} else {
		requestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
		url = `${questApiDomain}/number`;
		payload = {
			SourceClickKey: CLK || '',
			LandingQuadTagID: tagId || '',
			PC: zip,
			pcforlocalized: zipForLocalizedAssignment,
			PortalServiceCode: modForm.opts.vertical,
			service: 'assign',
			BusinessUnit: 'FC',
			QS_CATEGORY: 'Home Services',
			TradingPlatformTrackingInfo: '',
			vendorKeys: vendorKey ? vendorKey : '-1',
			quadTagId: isProductionEnv ? modForm.opts.phoneAssignmentConfig.questQuadTagId.prod : modForm.opts.phoneAssignmentConfig.questQuadTagId.staging,
			AffiliateSubID: PartnerSourceID || ''
		};

		if (currentParams && Object.keys(currentParams).length) {
			Object.keys(currentParams).forEach(function(key) {
				if (campaignAttributes.includes(key)) {
					TradingPlatformTrackingInfo[key] = currentParams[key];
				}
			});
		}

		payload.TradingPlatformTrackingInfo = JSON.stringify(TradingPlatformTrackingInfo);

		requestBody = new URLSearchParams(payload).toString();
	}

	abortController = new AbortController();
	const signal = abortController.signal;

	const requestOptions = {
		method: 'POST',
		headers: requestHeaders,
		redirect: 'follow',
		mode: 'cors',
		body: requestBody,
		signal
	};

	fetch(url, requestOptions)
		.then(response => response.json())
		.then(jsonResponse => {
			if (useHsEndpoint) {
				if (jsonResponse && jsonResponse.status === 'success') {
					const { data } = jsonResponse;
					const { phoneDetails } = data;
					let phoneNumberData;
					if (!phoneDetails) {
						phoneNumberData = modForm.opts.phoneAssignmentConfig.response;
					} else {
						phoneNumberData = phoneDetails[payload.matchVendors[0]];
					}
					const { phoneNumber, extension, phoneAssignmentKey } = phoneNumberData;
					showPhoneNumber(phoneNumber, extension, phoneAssignmentKey);
				} else {
					hidePhoneNumberContainers();
				}
			} else {
				if (jsonResponse && Object.keys(jsonResponse).length) {
					const phoneNumberData = jsonResponse[payload.vendorKeys];

					if (!phoneNumberData || phoneNumberData.constructor !== Object) {
						hidePhoneNumberContainers();
						return;
					}

					const { ccPhoneNum, extn } = phoneNumberData;
					showPhoneNumber(ccPhoneNum, extn);
				} else {
					hidePhoneNumberContainers();
				}
			}
		})
		.catch(error => {
			if (signal.aborted) {
				console.log(signal.reason);
				Modalytics.heap('track', ['Phone Assignment', {
					isRequestAborted: true
				}]);
			} else {
				hidePhoneNumberContainers();
				console.log('Something went wrong!', error);
			}
		});
};
